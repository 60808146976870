<template>
  <section id="section-home">
    <div id="main-bg-image" class="bgimg" :style="`background-image: url(` + require('@/assets/images/home/bg.jpg') + `)`"></div>
    <div class="panel-outer">
      <div class="panel">
        <div class="panel2">
          <div class="l1 style1 stagger1">From the director of ANNABELLE</div>
          <div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>
          <div class="dates stagger1">
            <div class="l-small">In theaters and on demand</div>
            <div class="l-big">NOW PLAYING</div>
            <div class="l-small">
              <button @click="openAction">
                watch now on demand
              </button>

            </div>
          </div>
          <button class="ytplayer stagger1" @click="gh.openOverlay('video')">
            <div class="bg">
              <video autoplay muted loop poster="@/assets/images/home/player.jpg">
                <source src="@/assets/images/home/player.mp4" type="video/mp4">
              </video>
              <img class="bg-frame" src="@/assets/images/home/player-frame.png">
            </div>
            <div class="icon stagger1">
              <div class="icon2">
                <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
              </div>
            </div>
          </button>
          <div class="l3 style2 stagger1">Sign up for updates & exclusive content.</div>
          <div class="signup stagger1">
            <EmailSignup
                mcDomain="https://welcomevillain.us20.list-manage.com"
                mcUserId="b25b645d846865015940afb4d"
                mcListId="b32f2ddfed"
            ></EmailSignup>
          </div>
          <div class="hashtag stagger1">#LilithIsHere</div>
        </div>
      </div>
    </div>
    <div id="order" :class="[(isOpen) ? 'open' : '']">
      <div @click="closeAction" class="scrim-closer"></div>
      <div class="order-inner"><div class="order-inner2">
        <div class="bugs">
          <button class="bug">
              <a href="https://bit.ly/3VG9xfZ" target="_blank">
                <img src="@/assets/images/order/apple.png"/>
              </a>
          </button>
          <button class="bug">
            <a href="https://bit.ly/3VpM0QA" target="_blank">
              <img src="@/assets/images/order/vudu.png"/>
            </a>
          </button>
          <button class="bug">
            <a href="https://www.amazon.com/Lullaby-Oona-Chaplin/dp/B0B6KFC6LB/ref=sr_1_1?crid=1NL28X3HNYL7E&keywords=lullaby&qid=1671212269&s=instant-video&sprefix=lullaby%2Cinstant-video%2C138&sr=1-1" target="_blank">
              <img src="@/assets/images/order/prime.png"/>
            </a>
          </button>
          <button class="bug">
            <a href="https://www.redbox.com/ondemand-movies/lullaby-2022" target="_blank">
              <img src="@/assets/images/order/redbox.png"/>
            </a>
          </button>
          <button class="bug">
            <a href="https://play.google.com/store/movies/details/Lullaby?id=110e6Tz9fKI.P" target="_blank">
              <img src="@/assets/images/order/google.png"/>
            </a>
          </button>
          <button class="bug">
            <a href="https://www.microsoft.com/en-us/p/lullaby/8d6kgwxzxkf9?activetab=pivot:overviewtab" target="_blank">
              <img src="@/assets/images/order/xbox.png"/>
            </a>
          </button>
        </div>
      </div></div>
      <div @click="closeAction" class="close-button-outer">
        <div class="close-button"></div>
      </div>
    </div>
  </section>
</template>

<script>
import gh from "@/helpers/gh"
import EmailSignup from '@/components/blocks/EmailSignup'
export default {
  name: "HomePage",
  components: {
    EmailSignup
  },
  data() {
    return {
      gh:gh,
      window:window,
      isOpen:false
    }
  },
  mounted() {
    this.window.addEventListener('keyup', this.escapeButtonPressed)
  },
  methods: {
    escapeButtonPressed(e) {
      if (e.key === "Escape") {
        this.closeAction()
      }
    },
    openAction() {
      this.isOpen = true
    },
    closeAction() {
      this.isOpen = false
    }
  }
}
</script>